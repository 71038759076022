<template>
    <a-card>
        <a-page-header
            :title='id == 0 ? "创建投放协议" : agreementStatus == 0 ? "投放协议审核" : "投放协议详情"'
        />
        <a-form-model
            ref="dataForm"
            :model="dataForm"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="广告主" prop='advertiserId'>
                <a-select
                    v-model="dataForm.advertiserId"
                    placeholder="请选择广告主"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                    :disabled="id != 0"
                    @search="onSearchAdvertiser"
                >
                    <a-select-option
                        v-for="item in advertiserList"
                        :key="item.advertiserId"
                    >
                        {{ item.advertiserName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="投放周期" prop='deliveryCycle'>
                <a-range-picker v-model="dataForm.deliveryCycle" style="width: 100%" @change="onChangeTime" />
            </a-form-model-item>
            <a-form-model-item label="目标车系" prop='targetCar'>
                <a-input
                    v-model="dataForm.targetCar"
                    placeholder="请填写目标车系"
                />
            </a-form-model-item>
            <a-form-model-item label="投放城市" prop='deliveryCity'>
                <a-input
                    v-model="dataForm.deliveryCity"
                    placeholder="请填写投放城市"
                />
            </a-form-model-item>
            <a-form-model-item label="投放渠道" prop='deliveryChannel'>
                <a-input
                    v-model="dataForm.deliveryChannel"
                    placeholder="请填写投放渠道"
                />
            </a-form-model-item>
            <a-form-model-item label="投放费用" prop='deliveryFee'>
                <a-input
                    v-model="dataForm.deliveryFee"
                    placeholder="请填写投放费用"
                    style="width: 100%"
                />
                <div class="yuan">元</div>
            </a-form-model-item>
            <a-form-model-item label="预估完成天数" prop='estimatedDaysToComplete'>
                <a-input-number
                    v-model="dataForm.estimatedDaysToComplete"
                    placeholder="请填写预估完成天数"
                    :min="0"
                    style="width: 100%"
                />
            </a-form-model-item>
            <a-form-model-item label="预估完成线索量" prop='estimatedAmountOfCollectedClue'>
                <a-input-number
                    v-model="dataForm.estimatedAmountOfCollectedClue"
                    placeholder="请填写预估完成线索量"
                    :min="0"
                    style="width: 100%"
                />
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="id == 0 ? '提交' : '保存'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            id: this.$route.query.id,
            agreementStatus: this.$route.query.agreementStatus,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            dataForm: {
                deliveryCycle: [],
            },
            rules: {
                advertiserId: [
                    { required: true, message: '请选择广告主', trigger: 'change' }
                ],
                deliveryCycle: [
                    { type: 'array', required: true, message: '请选择投放周期', trigger: 'change' }
                ],
                targetCar: [
                    { required: true, message: '请填写目标车系', trigger: 'change' }
                ],
                deliveryCity: [
                    { required: true, message: '请填写投放城市', trigger: 'change' }
                ],
                deliveryChannel: [
                    { required: true, message: '请填写投放渠道', trigger: 'change' }
                ],
                deliveryFee: [
                    { required: true, message: '请填写投放费用', trigger: 'change' }
                ],
                estimatedDaysToComplete: [
                    { required: true, message: '请填写预估完成天数', trigger: 'change' }
                ],
                estimatedAmountOfCollectedClue: [
                    { required: true, message: '请填写预估完成线索量', trigger: 'change' }
                ],
            },
            advertiserList: [],
            isSubmit: false,
            date_format: 'YYYY-MM-DD',
        }
    },
    created() {
        this.id != 0 ? this.getDetail() : ''

        this.getAccountList()
    },
    methods: {
        onChangeTime(date, dateString) {
            this.dataForm.deliveryCycle = date
            console.log(this.dataForm.deliveryCycle, date, dateString)
        },
        //获取广告主列表
        getAccountList(advertiserName) {
            this.$api.core.pphtManage.getAccountList({ advertiserName: advertiserName, page: 1, size: 1000 }).then((res) => {
                if(res.code == 200) {
                    this.advertiserList = res.data.list
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //搜索广告主
        onSearchAdvertiser(val) {
            this.getAccountList(val)
        },
        //获取详情
        getDetail() {
            this.$api.core.deliverManage.getAdvertiserAgreementDetail(this.id).then((res) => {
                if(res.code == 200) {
                    let _data = res.data
                    _data.deliveryCycle = [moment(_data.deliveryStartTime, this.date_format), moment(_data.deliveryEndTime, this.date_format)]

                    this.$set(this, 'dataForm', _data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/deliverManage'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.$refs.dataForm.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    let params = {}
                    this.dataForm.deliveryStartTime = `${moment(this.dataForm.deliveryCycle[0]).format('YYYY-MM-DD')} 00:00:00`
                    this.dataForm.deliveryEndTime = `${moment(this.dataForm.deliveryCycle[1]).format('YYYY-MM-DD')} 23:59:59`
                    if(this.id == 0) {
                        params = {
                            data: this.dataForm,
                            method: 'post'
                        }
                    } else {
                        params = {
                            data: Object.assign(this.dataForm, {id: this.id, isAudit: this.agreementStatus == 0 ? true : false}),
                            method: 'put'
                        }
                    }

                    this.$api.core.deliverManage.submitAdDeliveryAgreementDetail( params ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success(`${this.id == 0 ? '提交' : '更新'}成功`)
                            this.$router.push({
                                path: '/deliverManage'
                            })
                        } else {
                            this.$message.error(`${this.id == 0 ? '提交' : '更新'}失败，${res.message}`)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
/deep/ .ant-form-item-children {
    position: relative;
}
.yuan {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 10px;
    transform: translateY(-50%);
}
</style>